<template>
  <ion-grid class="no-pad">
    <ion-row
      class="
        ion-align-items-center ion-justify-content-center
        background-primary
        ion-padding
      "
    >
      <ion-col sizeXl="8" sizeMd="11" sizeXs="12">
        <img src="../assets/svg/howitworkswhite.svg" />
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import { IonCol, IonGrid, IonRow } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "HowItWorks",
  components: {
    IonCol,
    IonGrid,
    IonRow,
  },
});
</script>

import { IonContent, IonPage, IonGrid, IonCol, IonRow } from "@ionic/vue";
import { defineComponent } from "vue";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import HowItWorks from "../components/HowItWorks.vue";

export default defineComponent({
  name: "About",
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    Header,
    Footer,
    HowItWorks,
  },
});
